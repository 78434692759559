import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'lodash.get'
import React from 'react'
import { Bookmark } from 'react-feather'
import styled, { css } from 'styled-components'
import Button from '../components/button'
import HTMLBlock from '../components/html-content'
import Layout from '../components/layout'
import Responsive, { FlexCol, FlexRow, ResponsiveImage } from '../components/responsive'
import Seo from '../components/seo'
import Title from '../components/titles'
import colors from '../theme/colors'
import media from '../theme/media-queries'
const RejoignezNous = ({ data, uri }) => {
    const { uid } = get(data, 'prismicJoinUs')
    const { title, content, image, body } = get(data, 'prismicJoinUs.data')

    const slices = body.map(({ slice_type, primary = {}, items = [] }) => {
        switch (slice_type) {
            case 'jobs':
                return {
                    type: slice_type,
                    jobs: items.map(({ job }) => {
                        const { uid, document } = job
                        const { job_title, job_synth } = get(document, 'data')
                        return {
                            jobUid: uid,
                            title: get(job_title, 'text'),
                            content: get(job_synth, 'html'),
                        }
                    }),
                }
            case 'valeurs':
                const { valeurs_title, valeurs_content } = primary
                return {
                    type: slice_type,
                    title: get(valeurs_title, 'text'),
                    content: get(valeurs_content, 'html'),
                }
            case 'temoignages':
                const { temoignages_title } = primary
                return {
                    type: slice_type,
                    title: get(temoignages_title, 'text'),
                    temoignages: items.map(({ author, quote, avatar }) => {
                        return {
                            author,
                            quote: get(quote, 'html'),
                            image: get(avatar, 'localFile.childImageSharp.gatsbyImageData'),
                        }
                    }),
                }
            default:
                return null
        }
    })

    const jobsSlice = slices.find(({ type }) => type === 'jobs')
    const temoignagesSlice = slices.find(({ type }) => type === 'temoignages')
    const valeursSlice = slices.find(({ type }) => type === 'valeurs')

    return (
        <>
            <Seo title={get(title, 'text')} />
            <Layout uri={uri} withMiniHeader>
                <Section>
                    <Responsive>
                        <Title size="hero" as="h1" withDot dotSymbol=" !">
                            {get(title, 'text')}
                        </Title>
                        <MainPicture borderRadius="50%">
                            <GatsbyImage image={get(image, 'localFile.childImageSharp.gatsbyImageData')} alt="" />
                        </MainPicture>
                        <FlexRow align="flex-start" gutter="8rem" collapseTablet>
                            <FlexCol flex={7}>
                                <HTMLBlock>{get(content, 'html')}</HTMLBlock>
                                <JobBoard>
                                    {jobsSlice.jobs.map(({ title, content, jobUid }, index) => (
                                        <Job key={index}>
                                            <JobTitle>
                                                <Bookmark color={colors.vividRed} />
                                                <Title size="medium" as="h1">
                                                    {title}
                                                </Title>
                                            </JobTitle>
                                            <JobContent>
                                                <HTMLBlock>{content}</HTMLBlock>
                                            </JobContent>
                                            <JobFooter>
                                                <Link to={`/${uid}/${jobUid}`}>
                                                    <Button icon="ArrowRight">Voir plus</Button>
                                                </Link>
                                            </JobFooter>
                                        </Job>
                                    ))}
                                </JobBoard>
                            </FlexCol>
                            <FlexCol flex={4}>
                                <aside>
                                    <Title size="large" as="h2" withDot>
                                        {valeursSlice.title}
                                    </Title>
                                    <HTMLBlock>{valeursSlice.content}</HTMLBlock>
                                </aside>
                                <aside>
                                    <Title size="large" as="h2" withDot>
                                        {temoignagesSlice.title}
                                    </Title>
                                    {temoignagesSlice.temoignages.map(({ quote, author, image }, index) => (
                                        <Testimonial key={index}>
                                            <Quote>
                                                <HTMLBlock>{quote}</HTMLBlock>
                                            </Quote>
                                            <Avatar borderRadius="50%">
                                                <GatsbyImage image={image} alt="" />
                                            </Avatar>
                                            <FullName size="tiny" bold>
                                                {author}
                                            </FullName>
                                        </Testimonial>
                                    ))}
                                </aside>
                            </FlexCol>
                        </FlexRow>
                    </Responsive>
                </Section>
            </Layout>
        </>
    )
}

export const query = graphql`
    query JoinUs {
        prismicJoinUs {
            uid
            data {
                title {
                    text
                }
                content {
                    html
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 800, height: 800, placeholder: TRACED_SVG)
                        }
                    }
                }
                body {
                    ... on PrismicJoinUsBodyJobs {
                        slice_type
                        items {
                            job {
                                uid
                                document {
                                    ... on PrismicJob {
                                        data {
                                            job_title {
                                                text
                                            }
                                            job_synth {
                                                html
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicJoinUsBodyTemoignages {
                        slice_type
                        primary {
                            temoignages_title {
                                text
                            }
                        }
                        items {
                            author
                            avatar {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            transformOptions: { duotone: { highlight: "#FFFFFF", shadow: "#21294D" } }
                                            layout: CONSTRAINED
                                            width: 300
                                            height: 300
                                            placeholder: TRACED_SVG
                                        )
                                    }
                                }
                            }
                            quote {
                                html
                            }
                        }
                    }
                    ... on PrismicJoinUsBodyValeurs {
                        slice_type
                        primary {
                            valeurs_title {
                                text
                            }
                            valeurs_content {
                                html
                            }
                        }
                    }
                }
            }
        }
    }
`

const Section = styled.section`
    position: relative;
    overflow: hidden;
    z-index: 10;
    min-height: 90vh;
    padding: 4rem 0;
    ${(props) =>
        props.background &&
        css`
            background-color: ${props.background};
        `}
    ${(props) =>
        props.color &&
        css`
            color: white;
        `}
`

const JobBoard = styled.div``

const Job = styled.article`
    border-radius: 0.5rem;
    background: ${colors.beige};
    padding: 0 1rem;
    margin: 1rem 0 2rem;
`

const JobTitle = styled.header`
    display: flex;
    align-items: center;
    > svg {
        margin: 1em 0.4em 0.4em 0;
    }
`
const JobContent = styled.main`
    ${media.atLeastTablet`
        padding-left: 2rem;
    `}
`

const JobFooter = styled.footer`
    text-align: right;
    padding: 0 0 1rem;
`

const MainPicture = styled(ResponsiveImage)`
    position: absolute;
    width: 30vw;
    top: 0;
    right: 0;
    transform: translateY(-30%) translateX(25%);
`

const Testimonial = styled.aside`
    position: relative;
    transform: rotate(-1deg);
    margin: 1rem 0 4rem 2rem;
`

const Avatar = styled(ResponsiveImage)`
    position: absolute;
    width: 60px;
    bottom: 0;
    left: 0;
    transform: translateX(-50%) translateY(10%);
`

const Quote = styled.blockquote`
    position: relative;
    padding: 1rem 1rem 1rem 2rem;
    margin-bottom: 0.5rem;
    background-color: aliceblue;
    border-radius: 2rem 2rem 2rem 0;
    &:before,
    &:after {
        display: block;
        position: absolute;
    }
    & :before {
        content: '“';
        font-size: 5em;
        top: 0;
        left: 0;
        transform: translateX(-0.5rem) translateY(0.5rem);
    }
    & :after {
        content: '”';
        font-size: 5em;
        bottom: 0;
        right: 0;
        transform: translateX(1rem) translateY(1.5rem);
    }
    & p {
        font-size: 0.9rem;
        line-height: 1.4rem;
        padding: 0;
    }
`

const FullName = styled(Title)`
    margin-left: 2rem;
    /* position: absolute;
    bottom: 0;
    transform: translateY(1.2rem); */
`

export default RejoignezNous
